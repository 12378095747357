import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ContactService } from "src/app/modules/static/services/contact.service";
import { GoogleAnalyticsService } from "../../services/google-analytics.service";

export enum sendStatus {
  SENDING = 'SENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

@Component({
  selector: "ping-send-download-sms",
  templateUrl: "./send-download-sms.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./send-download-sms.component.scss"]
})


export class SendDownloadSMSComponent implements OnInit, AfterViewInit {

  public isMobile: boolean;

  public telInput: string;
  public sendSmsStatus: sendStatus;

  constructor(
    private analytics: GoogleAnalyticsService,
    private cd: ChangeDetectorRef,
    private contactService: ContactService,
  ) {}

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  public async submitSms() {
    if (this.telInput && this.telInput !== '') {
      this.sendSmsStatus = sendStatus.SENDING;
      this.cd.detectChanges();
      const message: string = "Here is your Ping Culture download link: https://pingapp.page.link/hi";
      const sendMessage = await this.contactService.sendSms(this.telInput, message);
      if (sendMessage) {
        this.sendSmsStatus = sendStatus.SUCCESS;
        this.analytics.trackFbEvent('Lead', {content_name: 'sms', value: 1});
        this.analytics.trackMixpanelEvent('app-download', { platform: 'sms' });
        this.analytics.trackEvent('Download Link', 'Download Modal SMS');
      } else {
        this.sendSmsStatus = sendStatus.ERROR;
      }
      this.cd.detectChanges();
    }
  }
}
