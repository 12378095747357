
<ng-container *ngIf="sendSmsStatus !== 'SUCCESS' && sendSmsStatus !== 'SENDING'">
  <mat-form-field class="w-100 mt-3" style="max-width: 300px">
    <mat-label><span class="font-weight-light">Text me a download link</span></mat-label>
    <ngx-mat-intl-tel-input autoFocus (keyup.enter)="submitSms()" (ngModelChange)="sendSmsStatus = null" [(ngModel)]="telInput" class="w-100" [preferredCountries]="['gb', 'us', 'za']" [enablePlaceholder]="true"
      [enableSearch]="true" name="phone" #phone></ngx-mat-intl-tel-input>

    <button *ngIf="telInput && telInput !== ''" matSuffix mat-icon-button aria-label="Send" (click)="submitSms()">
      <fa-icon [icon]="faSend"></fa-icon>
    </button>
  </mat-form-field>

</ng-container>

<p [ngSwitch]="sendSmsStatus" class="small mb-0">
  <span *ngSwitchCase="'SENDING'" class="text-medium pt-3">Sending</span>
  <span *ngSwitchCase="'SUCCESS'" class="text-success pt-3">Sent successfully</span>
  <span *ngSwitchCase="'ERROR'" class="text-danger">Error sending, please try again.</span>
</p>
