import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { ApiService, ErrorType } from 'src/app/core/services/api.service';
import { sendContactFormEmail, sendSMS } from '../api/static.queries';
@Injectable({
  providedIn: 'root'
})
export class ContactService extends ApiService{

  constructor() { super() }


  public async sendContactEmail(formDetails?: any, type?: string): Promise<boolean> {
    const argumentsJson: any = JSON.stringify({
      ...formDetails,
      type
    });
    const res: any = await this.runQuery(sendContactFormEmail, { arguments: argumentsJson } ,'sendContactFormEmail', true).pipe(first()).toPromise();
    if (res) {
      if ((res as ErrorType) === ErrorType.ERROR) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  public async sendSms(phoneNumber: string, message: string): Promise<boolean> {
    const argumentsJson: any = JSON.stringify({
      phoneNumber,
      message
    });
    const res: any = await this.runQuery(sendSMS, { arguments: argumentsJson } ,'sendSMS', true).pipe(first()).toPromise();
    if (res) {
      if ((res as ErrorType) === ErrorType.ERROR) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
}
