export const sendContactFormEmail = /* GraphQL */ `
  query SendContactFormEmail($arguments: AWSJSON) {
    sendContactFormEmail(arguments: $arguments)
  }
`;

export const sendSMS = /* GraphQL */ `
  query SendSMS($arguments: AWSJSON) {
    sendSMS(arguments: $arguments)
  }
`;
